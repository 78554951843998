const theme = {
  // space: [0, 4, 8, 10, 12, 16, 20, 24, 32, 54, 64, 84, 102, 128],
  space: {
    0: "0px", // 0
    px: "1px", // 1px
    0.5: "0.125rem", // 2px
    1: "0.25rem", // 4px
    1.5: "0.375rem", // 6px
    2: "0.5rem", // 8px
    2.5: "0.625rem", // 10px
    3: "0.75rem", // 12px
    3.5: "0.875rem", // 14px
    4: "1rem", // 16px
    5: "1.25rem", // 20px
    6: "1.5rem", // 24px
    7: "1.75rem", // 28px
    8: "2rem", // 32px
    9: "2.25rem", // 36px
    10: "2.5rem", // 40px
    12: "3rem", // 48px
    14: "3.5rem", // 56px
    16: "4rem", // 64px
    18: "4.5rem", // 72px
    20: "5rem", // 80px
    24: "6rem", // 96px
    28: "7rem", // 112px
    32: "8rem", // 128px
    36: "9rem", // 144px
    40: "10rem", // 160px
    44: "11rem", // 176px
    48: "12rem", // 192px
    56: "14rem", // 224px
    60: "15rem", // 240px
    64: "16rem", // 256px
    72: "18rem", // 288px
    80: "20rem", // 320px
    96: "24rem", // 384px
  },
  sizes: {
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    half: "50%",
    full: "100%",
    screenHeight: "100vh",
    screenWidth: "100vw",
  },
  fonts: {
    body: "'D-DIN', sans-serif",
    bodyHeavy: "'D-DIN Bold', sans-serif",
    heading: "'Hudson NY Regular', sans-serif",
    monospace: "sans-serif",
    hero: "'Hudson NY Regular', sans-serif",
  },
  fontSizes: [
    14, // 0
    16, // 1
    18, // 2
    24, // 3
    36, // 4
    48, // 5
    64, // 6
  ],
  breakpoints: ["640px", "768px", "996px", "1024px", "1280px"],
  fontWeights: {
    thin: 300,
    body: 400,
    heading: 400,
    bold: 700,
    extrabold: 900,
    hero: 400,
  },
  lineHeights: {
    body: 1.4,
    heading: 1.1,
  },
  colors: {
    text: "#231F20",
    textWhite: "#ffffff",
    background: "#ffffff",
    primary: "#A18347",
    secondary: "#E1251B",
    tan: "#D6D1C4",
    lightTan: "#F5F3EC",
    lightGray: "#DBDBDC",
    darkBackground: "#1E1E1E",
    defaultOverlay:
      "linear-gradient(90deg, hsla(0, 0%, 12%, 1) 0%, hsla(0, 0%, 12%, 1) 100%);",
  },
  shadows: ["0px 4px 5px rgba(0, 0, 0, 0.1)"],
  container: {
    maxWidth: ["610px", "738px", "966px", "994px", "1250px"],
  },
  buttons: {
    primary: {
      display: "inline-block",
      color: "text",
      cursor: "pointer",
      outline: "none",
      border: 0,
      fontSize: [0, null, null, 2, null],
      fontFamily: "body",
      fontWeight: "thin",
      lineHeight: "body",
      position: "relative",
      textDecoration: "none",
      textTransform: "uppercase",
      backgroundColor: "transparent",
      py: 2.5,
      px: 9,
      transition: "all 0.3s cubic-bezier(0.19, 1, 0.22, 1)",
      "&:before,&:after": {
        content: "''",
        width: "calc(100% - 2px)",
        height: "calc(100% - 2px)",
        position: "absolute",
        border: "0px solid #fff",
        transition: "all 0.3s cubic-bezier(0.19, 1, 0.22, 1)",
      },
      "&:before": {
        top: 0,
        left: 0,
        borderTop: "2px solid #A18347",
        borderLeft: "2px solid #A18347",
      },
      "&:after": {
        bottom: 0,
        right: 0,
        borderBottom: "2px solid #A18347",
        borderRight: "2px solid #A18347",
      },
      "&:hover": {
        fontFamily: "bodyHeavy",
        "&:before, &:after": {
          width: "20px",
          height: "20px",
        },
        "&:before": {
          borderTop: "5px solid #A18347",
          borderLeft: "5px solid #A18347",
        },
        "&:after": {
          borderBottom: "5px solid #A18347",
          borderRight: "5px solid #A18347",
        },
      },
    },
    secondary: {
      position: "relative",
      display: "inline-block",
      cursor: "pointer",
      fontFamily: "bodyHeavy",
      textTransform: "uppercase",
      color: "textWhite",
      bg: "secondary",
      outline: "none",
      textDecoration: "none",
      borderRadius: 0,
      minWidth: "max-content",
      fontSize: [0, null, null, 2, null],
      px: 5,
      py: 2.5,
      ".link-text": {
        display: "inline-block",
        transform: "translateX(0px)",
        transition: "all 0.3s ease-in-out",
        willChange: "transform",
      },
      ".link-arrow": {
        opacity: 0,
        transform: "translateX(-10px)",
        willChange: "transform, opacity",
      },
      "&:hover": {
        ".link-text": {
          transform: "translateX(-10px)",
          transition: "all 0.3s ease-in-out",
        },
        ".link-arrow": {
          opacity: 1,
          transform: "translateX(10px)",
        },
      },
    },
    solidPrimary: {
      backgroundColor: "primary",
      borderRadius: 0,
      color: "white",
      cursor: "pointer",
      fontFamily: "body",
      fontSize: 1,
      fontWeight: "bold",
      p: 4,
      textAlign: "center",
      textDecoration: "none",
      textTransform: "uppercase",
    },
    textOnly: {
      appearance: "none",
      position: "relative",
      display: "inline-block",
      cursor: "pointer",
      fontFamily: "bodyHeavy",
      textTransform: "uppercase",
      color: "primary",
      bg: "none",
      outline: "none",
      textDecoration: "none",
      borderRadius: 0,
      fontSize: [0, null, null, 2, null],
      px: 0,
      py: 2.5,
      ".link-text": {
        mr: 4,
      },
      ".link-arrow": {
        transition: "all 0.3s ease-in-out",
        transform: "translateX(0)",
        willChange: "transform",
      },
      "&:hover, &:focus": {
        ".link-arrow": {
          transition: "all 0.3s ease-in-out",
          transform: "translateX(10px)",
        },
      },
    },
  },
  forms: {
    input: {
      display: "inline-block",
      backgroundColor: "transparent",
      position: "relative",
      border: 0,
      borderBottomWidth: "2px",
      borderBottomColor: "text",
      borderBottomStyle: "solid",
      borderRadius: 0,
      outline: "none",
      zIndex: 1,
      p: 0,
      pb: 2,
      fontFamily: "bodyHeavy",
      fontSize: [1, null, 2, null, null],
      transition: "color 0.3s ease-out",
      "&:focus": {
        outline: 0,
        // boxShadow: 0,
        borderBottomColor: "primary",
        transition: "all 0.3s ease-out",
      },
      "&:focus, &:not(:placeholder-shown)": {
        "& + label": {
          transition: "all 0.3s ease-in-out",
          transform: "translate(3%, 150%) scale(0.8)",
        },
      },
      "&::placeholder": {
        color: "transparent",
      },
      "& + label": {
        transition: "all 0.3s ease-out",
        transformOrigin: "0 0",
        position: "absolute",
        top: 0,
        left: 0,
        userSelect: "none",
        zIndex: 0,
      },
      "&:disabled": {
        color: "gray",
      },
    },
    select: {
      display: "inline-block",
      position: "relative",
      border: 0,
      backgroundColor: "transparent",
      borderBottomWidth: "2px",
      borderBottomColor: "text",
      borderBottomStyle: "solid",
      borderRadius: 0,
      outline: "none",
      zIndex: 1,
      p: 0,
      pb: 2,
      fontFamily: "bodyHeavy",
      fontSize: [1, null, 2, null, null],
      transition: "color 0.3s ease-out",
      "&:focus": {
        outline: 0,
        // boxShadow: 0,
        borderBottomColor: "primary",
        transition: "all 0.3s ease-out",
      },
    },
    textarea: {
      display: "inline-block",
      position: "relative",
      backgroundColor: "transparent",
      borderWidth: "2px",
      borderColor: "text",
      borderStyle: "solid",
      borderRadius: 0,
      outline: "none",
      zIndex: 1,
      p: 6,
      fontFamily: "bodyHeavy",
      fontSize: [1, null, 2, null, null],
      transition: "color 0.3s ease-out",
      "&:focus": {
        outline: 0,
        borderColor: "primary",
        transition: "all 0.3s ease-out",
      },
    },
    checkbox: {
      color: "primary",
      borderRadius: 0,
      cursor: "pointer",
      "input:checked ~ &": {
        "> path": {
          fill: "primary",
          strokeWidth: 1,
        },
      },
      "input:focus ~ &": {
        background: "transparent",
        border: "thin",
        "> path": {
          strokeWidth: 2,
        },
      },
    },
    label: {
      fontSize: [1, null, 2, null, null],
      lineHeight: "24px",
      fontFamily: "body",
      fontWeight: "body",
      color: "inherit",
    },
  },
  text: {
    hero: {
      fontFamily: "hero",
      fontWeight: "hero",
      textTransform: "uppercase",
      color: "inherit",
      fontSize: [4, null, 6, null, null],
      lineHeight: "1.2",
      m: 0,
    },
    // default for <Heading> component, use for h2
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      textTransform: "uppercase",
      fontSize: [3, null, 4, null, null],
    },
    title: {
      // use for h3
      fontSize: [2, null, 3, null, null],
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      textTransform: "uppercase",
      marginTop: 0,
      a: {
        color: "inherit",
      },
    },
    subtitle: {
      // use for h4
      fontSize: [2, null, 3, null, null],
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      textTransform: "uppercase",
      marginTop: 0,
      a: {
        color: "inherit",
      },
    },
    bodyTitle: {
      // use for h5
      fontSize: [0, null, 2, null, null],
      fontFamily: "bodyHeavy",
      fontWeight: "700",
      lineHeight: "heading",
      textTransform: "uppercase",
      color: "inherit",
      marginTop: 0,
      a: {
        color: "inherit",
        textDecoration: "none",
      },
    },
    bodyTitleThin: {
      // use for h5
      fontSize: [0, null, 2, null, null],
      fontFamily: "body",
      fontWeight: "400",
      lineHeight: "heading",
      textTransform: "uppercase",
      marginTop: 0,
      opacity: 0.5,
      color: "text",
      a: {
        color: "inherit",
        textDecoration: "none",
      },
    },
    productCategoryTitle: {
      fontSize: [0, null, 1, null, null],
      fontFamily: "bodyHeavy",
      fontWeight: "700",
      lineHeight: "body",
      textTransform: "uppercase",
      color: "inherit",
      marginTop: 0,
      a: {
        color: "inherit",
        textDecoration: "none",
      },
    },
    productCategoryDetail: {
      fontFamily: "body",
      fontSize: [0, null, 1, null, null],
      color: "inherit",
      textTransform: "uppercase",
    },
    productTableHeading: {
      fontSize: [0, null, 2, null, null],
      fontFamily: "bodyHeavy",
      fontWeight: "700",
      lineHeight: "heading",
      textTransform: "uppercase",
      color: "inherit",
      textAlign: "left",
      marginTop: 0,
      a: {
        color: "inherit",
        textDecoration: "none",
      },
      whiteSpace: "nowrap",
      px: 5,
    },
    test2: {
      fontFamily: "bodyHeavy",
      fontSize: "30px",
      textTransform: "uppercase",
      color: "text",
    },
    sectionHeading: {
      fontFamily: "heading",
      fontSize: "30px",
      textTransform: "uppercase",
      mr: 8,
    },
    paragraph: {
      color: "inherit",
      fontFamily: "body",
      fontSize: [2, null, 3, null, null],
      fontWeight: "body",
      lineHeight: "body",
      my: 3,
    },
    paragraphHeavy: {
      color: "inherit",
      fontFamily: "bodyHeavy",
      fontSize: [0, null, 1, null, null],
      fontWeight: "700",
      lineHeight: "body",
      my: 3,
    },
    srOnly: {
      height: 1,
      left: -10000,
      overflow: "hidden",
      position: "absolute",
      top: "auto",
      width: 1,
    },
  },
  links: {
    nav: {
      color: "inherit",
      textDecoration: "none",
      fontSize: [0, null, null, null, null, 1],
      fontFamily: "body",
      fontWeight: "body",
      textTransform: "uppercase",
      transition: "color 0.3s ease-in-out",
      mr: [0, null, null, 3, null, 5],
      position: "relative",
      // "::before": {
      //   content: `attr(title)`,
      //   fontWeight: "extrabold",
      //   left: 0,
      //   opacity: 0,
      //   position: "absolute",
      //   top: "50%",
      //   transform: "translateY(-50%)",
      //   transition: "all 0.3s ease-in-out",
      //   width: "100%",
      // },
      "::after": {
        backgroundColor: "primary",
        bottom: "-10px",
        content: '""',
        height: "4px",
        right: "10%",
        position: "absolute",
        transition: "width 0.3s ease-in-out",
        width: 0,
      },
      ":hover": {
        // "::before": {
        //   opacity: 1,
        // },
        textShadow: [
          "0 0.015em #ffffff,0 -0.015em #ffffff,0.01em 0 #ffffff,-0.01em 0 #ffffff",
          null,
          null,
          "0 0.015em #101010,0 -0.015em #101010,0.01em 0 #101010,-0.01em 0 #101010",
        ],
        WebkitTextFillColor: ["white", null, null, "black"],
        WebkitTextStrokeWidth: "1px",
        WebkitTextStrokeColor: ["white", null, null, "black"],
        "::after": {
          left: "10%",
          width: [null, null, null, "80%"],
        },
      },
    },
    navDropDownLink: {
      color: "inherit",
      textDecoration: "none",
      fontSize: [0, null, null, null, null, 1],
      fontFamily: "body",
      textTransform: "uppercase",
      transition: "color 0.3s ease-in-out",
      mr: [null, null, null, 3, null, 5],
      position: "relative",
      // "::before": {
      //   content: `attr(title)`,
      //   fontWeight: "extrabold",
      //   left: 0,
      //   opacity: 0,
      //   position: "absolute",
      //   top: "50%",
      //   transform: "translateY(-50%)",
      //   transition: "all 0.3s ease-in-out",
      //   width: "100%",
      // },
      ":hover": {
        // "::before": {
        //   opacity: 1,
        // },
        textShadow: [
          "0 0.015em #ffffff,0 -0.015em #ffffff,0.01em 0 #ffffff,-0.01em 0 #ffffff",
          null,
          null,
          "0 0.015em #101010,0 -0.015em #101010,0.01em 0 #101010,-0.01em 0 #101010",
        ],
        WebkitTextFillColor: ["white", null, null, "black"],
        WebkitTextStrokeWidth: "1px",
        WebkitTextStrokeColor: ["white", null, null, "black"],
      },
    },
    footerHeaderNav: {
      color: "inherit",
      fontSize: [2, null, 3, null, null],
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      textTransform: "uppercase",
      marginTop: 0,
      textDecoration: "none",
    },
    footerNav: {
      color: "inherit",
      textDecoration: "none",
      fontSize: [1, null, 2, null, null],
      fontFamily: "body",
      textTransform: "capitalize",
      transition: "color 0.3s ease-in-out",
      mb: 3,
      "&:hover": {
        color: "primary",
      },
    },
    blogLink: {
      display: "inline-block",
      color: "primary",
      textDecoration: "none",
      textTransform: "uppercase",
      fontSize: [0, null, 1, null, null],
      fontFamily: "heading",
      fontWeight: "bold",
      transition: "color 0.3s ease-in-out",
      "&:hover": {
        color: "secondary",
      },
    },
    heroBacklink: {
      color: "inherit",
      fontSize: [1, null, 3, null, null],
      fontFamily: "bodyHeavy",
      fontWeight: "700",
      textTransform: "uppercase",
      textDecoration: "none",
      transition: "color 0.3s ease-in-out",
      lineHeight: "1",
      "&:hover": {
        color: "secondary",
      },
    },
  },
  layout: {
    container: {
      maxWidth: 1220,
      margin: "0 auto",
      px: 4,
    },
    wide: {
      maxWidth: 996,
      margin: "0 auto",
      px: 4,
    },
    narrow: {
      maxWidth: 787,
      margin: "0 auto",
      px: 4,
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    srOnly: {
      height: 1,
      left: -10000,
      overflow: "hidden",
      position: "absolute",
      top: "auto",
      width: 1,
    },
    h1: {
      color: "inherit",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [2, 3, 3],
      my: 6,
    },
    h2: {
      color: "inherit",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      textTransform: "uppercase",
      fontSize: [2, 3, 3],
      p: 0,
      mx: 0,
      my: 6,
    },
    h3: {
      fontSize: [1, null, 3, null, null],
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      textTransform: "uppercase",
      p: 0,
      mx: 0,
      my: 4,
      a: {
        color: "inherit",
      },
    },
    h4: {
      color: "inherit",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 0,
    },
    h5: {
      color: "inherit",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 0,
    },
    h6: {
      color: "inherit",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: "12px",
    },
    p: {
      color: "inherit",
      fontFamily: "body",
      fontSize: [2, null, 3, null, null],
      fontWeight: "body",
      lineHeight: "body",
      my: 3,
    },
    a: {
      color: "primary",
      cursor: "pointer",
      textDecoration: "none",
      transition: "all 0.3s ease-in-out",
      fontSize: [0, null, 1, null, null],
      "&:hover": {
        cursor: "pointer",
        color: "secondary",
      },
    },
    ul: {
      variant: "text.paragraph",
      px: 5,
    },
    hr: {
      width: "100%",
      height: "2px",
      m: 0,
      backgroundColor: "primary",
      outline: "none",
      border: "none",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: "0",
      tableLayout: "fixed",
    },
    th: {
      textAlign: "left",
      textTransform: "uppercase",
    },
    td: {
      textAlign: "left",
      textTransform: "uppercase",
      long: {
        whiteSpace: "nowrap",
      },
    },
    img: {
      maxWidth: "100%",
      height: "auto",
      verticalAlign: "middle",
    },
    input: {
      outline: "none",
      border: "1px solid",
      borderColor: "inherit",
      padding: "10px 20px",
    },
    iframe: {
      display: "block",
      maxWidth: "100%",
      mx: "auto",
      my: 10,
      // aspectRatio: "16 / 9",
      height: "418px",
      width: "100%",
    },
  },
}

export default theme
